.company-partners {
    text-align: center;
    padding: 30px;
}

.company-partners h2 {
    font-size: 24px;
    margin-bottom: 30px;
}

.partners-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 20px;
}

.partner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.partner img {
    width: 150px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 10px;
    border-radius: 20px;
}