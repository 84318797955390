.video-container {
    position: relative;
    width: 100%;
   
  }
  
 
  
  .video-title {
    font-size: 24px;
    margin: 0;
  
  }
  
  .video-description {
    position: absolute;
    top: 140px; /* Adjust top positioning */
    margin-left: 30%;/* Adjust left positioning */
   
    font-size: 22px;
    background-color: transparent; /* Semi-transparent background */
    
    color: white;
  }
  
  .video-edit{
    position: absolute;
    top: 20px; /* Adjust top positioning */
    margin-left: 95%;/* Adjust left positioning */
   width: 28px;
    font-size: 15px;
    background-color: white; /* Semi-transparent background */
    border-radius:90px;
    color: grey;
    padding: 2px;
    text-align: center;
  }

 