.hero {
  background-image: url("../../../../assets/images/front.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 460px;
  background-color: #f7f9fc;
  /* width: 100%; */
}

.hero .ej_container {
  padding-top: 50px;
}

.hero h1 {
  color: #fff;
  font-size: 45px;
  text-align: left;
  line-height: 60px;
  width: 650px;


}

.hero p {
  color: #fff;
  font-size: 20px;
  opacity: 0.8;
  text-align: left;
  margin-top: 15px;
}

.background-form {
  padding: 40px 0;
  position: relative;
  height: 220px;
}

.form-img {
  position: absolute;
  width: 20%;
  left: 15%;
  top: -25px;
  z-index: 8;
}

.my-form {
  border: 3px solid #EFF0F9;
  border-radius: 5px;
  padding: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.form-imag {
  position: absolute;
  width: 20%;
  right: 14.5%;
  bottom: -120px;
}



.my-form .box {
  padding: 5px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}

.my-form .box:nth-child(1) {
  border-left: none;
}

.my-form h4 {
  font-weight: 500;
}

.filter-btn1 {
  background-color: gray;
}

@media (max-width: 768px) {
  .hero {
    background-image: none;
    background-color: #0425CC;
    padding: 5px;
    height: auto;
  }

  .hero .ej_container {
    max-width: 90%;
    text-align: center;
  }

  .hero h1 {
    font-size: 30px !important;
    line-height: 40px;
    width: 100%;
  }

  .hero p {
    font-size: 20px;
  }
}

/* 
@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 30%;
  }

  .my-form .box {
    border-left: none;
  }

  .my-form input {
    width: 100%;
  }
} */