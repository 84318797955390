header {
  background-color: #fff;
  height: 4.5rem;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding:5px 0;
  position: sticky;
  top: 0;
  z-index: 999;
}

header img {
  width: 110px;
  height: 60px;
  margin-bottom: 1rem;
}

header a {
  transition: 0.5s;
  font-weight: 500;
}

header a:hover {
  color: #0425CC;
}

header span {
  width: auto;
  padding: 0px 6px;
  background: #0425CC;
  border-radius: 50px;
  color: #ffffff;
  margin-right: 4px;
  font-weight: 400;
}

header h4 {
  font-weight: 500;
  margin-right: 20px;
}

header .toggle {
  display: none;
}

@media screen and (max-width: 768px) {
  header {
    padding: 0;
  }

  header img {
    /* margin-top: 30px; */
  }

  header .button {
    display: none;
  }

  header .nav .flex {
    display: none;
  }

  header .toggle {
    display: block;
    margin-top: 20px;
  }

  header .small {
    position: absolute;
    top: 10vh;
    left: 0;
    width: 100%;
    background-color: rgb(190, 185, 185);
    padding: 20px;
  }

  header li a {
    display: inline-block;
    margin-bottom: 20px;
    color: #fff;
  }
}